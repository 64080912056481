import logo from "../assets/picto.png";
import React, {useEffect} from "react";
import {useAddToHomescreenPrompt} from "../hooks/InstallPrompt";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function InstallFirst() {
    const {t} = useTranslation();
    const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches
    const navigate = useNavigate();
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod|mac/.test(userAgent);
    }
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const installAndContinue = (e: React.MouseEvent) => {
        if (!isInStandaloneMode() && !isIos()) {
            promptToInstall();
            return;
        }

        // @ts-ignore
        document.getElementById('installButton').innerHTML = 'Installation terminée.\n' +
            'Fermes ce navigateur.'

        //navigate('/');
    }

    useEffect(() => {
        if(isInStandaloneMode()){
            navigate('/');
        }else{
            localStorage.clear();
        }
    }, []);

    return (
        <div>
            <div className='container p-4 col-12 col-md-4 ms-auto me-auto'>
                <div className='text-center'>
                    <img
                        src={logo}
                        className="img-fluid mt-3 mb-3"
                        style={{
                            width: '25%'
                        }} alt=''/>
                </div>
                <div className='text-center'>
                    <h3>{t('installation.title')}</h3>
                </div>
                <div className='fw-bold'>
                    1. Installation
                </div>
                <div>
                    <strong className='text-blue'>{t('installation.on-android')}</strong>
                    <ul className='mb-0'>
                        <li>
                            {t('installation.step1')}
                        </li>
                    </ul>
                    <strong className='text-blue'>{t('installation.on-ios')}</strong>
                    <ul className='mb-0'>
                        <li>
                            <span className='text-blue'>{t('installation.if-safari')}</span>,
                            {t('installation.ios1')}
                        </li>
                        <li>
                            {t('installation.ios2')}
                        </li>
                    </ul>
                    <strong className='text-blue'>{t('installation.close')}</strong>
                </div>
                <div className='fw-bold mt-3'>
                    {t('installation.account-creation')}
                </div>
                <ul>
                    <li>
                        {t('installation.open')} <img
                        src={logo}
                        className="img-fluid"
                        style={{
                            width: '25px'
                        }} alt=''/>
                    </li>
                    <li>
                        {t('installation.create')}
                    </li>
                    <li>
                        {t('installation.assign')}
                    </li>
                </ul>
                {!isInStandaloneMode() && (
                    <div className='text-center mt-3' id='installButton'>
                        <button className='btn btn-outline-dark'
                                onClick={e => installAndContinue(e)}>
                            {t('installation.install')}
                        </button>
                    </div>
                )}
            </div>

        </div>
    )
}