import logo from "../assets/picto.png";
import React, {useState} from "react";
import CorrespondentBlock from "./CorrespondentBlock";
import {toast} from "react-toastify";
import {isValidPhoneNumber} from "libphonenumber-js";
import {Contact} from "../models/Contact";
import {Link, useNavigate} from "react-router-dom";
import {validateEmail} from "../helpers/utilities";
import {Trans, useTranslation} from "react-i18next";

export default function CreateCorrespondents({setSteps, first = true}: { setSteps: any, first: boolean }) {

    const {t} = useTranslation();
    const getContactsFromLocalStorage = (): Contact[] | null => {
        const savedContacts = localStorage.getItem('correspondents');
        if (savedContacts) {
            return JSON.parse(savedContacts) as Contact[];
        }
        return null;
    };
    const [contacts, setContacts] = useState<Contact[]>(() => {
        return getContactsFromLocalStorage() || [
            {
                name: '',
                number: '',
                email: ''
            },
        ]
    });
    const navigate = useNavigate();

    const validateCorrespondents = () => {
        let save = true;
        contacts.forEach((value: any, index) => {
            if (value.name === '') {
                toast.error(`Le nom du contact Nº${index + 1} est vide`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                save = false;
                return;
            }

            if (value.email === '') {
                toast.error(`L'email du contact Nº${index + 1} est vide`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                save = false;
                return;
            }else{
                if(!validateEmail(value.email)){
                    toast.error(`L'email du contact Nº${index + 1} est invalide`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    save = false;
                    return;
                }
            }

            if (!isValidPhoneNumber(value.number as string)) {
                toast.error(`Le numéro du contact Nº${index + 1} est invalide`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                save = false
                return;
            }
        });

        if (save) {
            localStorage.setItem('correspondents', JSON.stringify(contacts));
            const user = JSON.parse(localStorage.getItem('user') || '[]');
            if (Object.keys(user).length > 0) {
                contacts.forEach((v: any) => {
                    const request = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            content: `Bonjour ${v.name}, <br/> ${user.name}, 
au tél. nº (${user.number}) s’est équipé de l’application d’appel au secours Urgent Assistance.
<br/>En cas de problème sanitaire ou de sécurité physique, elle permet en seulement 2 clics, d’envoyer un sms vers un à 
trois contacts d’urgence choisis parmi les proches.<br/>${user.name} vous a désigné comme contact d’urgence. 
<br/><br/>Urgent Assistance est une application d’une grande simplicité d’utilisation conçue pour rompre la solitude et 
contacter rapidement les proches.<br>Elle est destinée aux personnes vivant seules qui recherchent plus de sérénité pour 
leur santé ou leur sécurité, mais également aux familles qui veulent sécuriser leurs enfants sur le trajet école-domicile, 
par exemple.<br>En plus, pas besoin d’aller dans les stores, vous la téléchargez directement sur notre site web.
<br><br>Bien cordialement<br>Le service Marketing`,
                            to: v.number,
                            email: v.email,
                            subject: "Designer en tant que correspondant d'urgence",
                        })
                    };
                    fetch(`${process.env.REACT_APP_API_URL}/send-email`, request)
                })
            }

            localStorage.setItem('first_launch', 'no');
            navigate('/');
        }
    }

    const addContact = () => {
        let tmp = [...contacts];
        tmp.push({
            name: '',
            number: '',
            email: ''
        });
        setContacts(tmp);
    }

    return (
        <div className={`container ${first ? 'p-4' : ''} col-12 col-md-4 ms-auto me-auto`}>
            {!first &&
                <nav className="navbar bg-body-tertiary fixed-bottom">
                    <div className="container-fluid">
                        <Link to='/' className="navbar-brand mb-0 h1">{t("buttons.return")}</Link>
                    </div>
                </nav>}
            <div className='text-center'>
                <img
                    src={logo}
                    className="img-fluid mt-3 mb-3"
                    style={{
                        width: '35%'
                    }} alt=''/>
            </div>
            <div className='text-center mb-3'>
                <h3>
                    <Trans i18nKey={'form-elements.assign-contact'}></Trans>
                </h3>
            </div>
            {contacts.map((v: object, i: number) => (
                <CorrespondentBlock number={i + 1} index={i} setContacts={setContacts} contacts={contacts} key={i}/>
            ))}
            <div className=''>
                {contacts.length < 3 &&
                    <div className='d-flex justify-content-center mt-5'>
                        <button className='btn btn-outline-dark' onClick={addContact}>
                            {t('buttons.add-correspondent')}
                        </button>
                    </div>}
            </div>
            <div className='text-center mt-3'>
                <button className='btn btn-primary'
                        onClick={validateCorrespondents}
                >
                    {t('buttons.validate')}
                </button>
            </div>
        </div>
    )
}