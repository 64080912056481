import React, {useEffect, useState} from "react";
import logo from "../assets/picto.png";
import {useAddToHomescreenPrompt} from "../hooks/InstallPrompt";
import {useNavigate} from "react-router-dom";
import AccountCreation from "../components/AccountCreation";
import CreateCorrespondents from "../components/CreateCorrespondents";
import UserSetup from "../components/UserSetup";
import {useTranslation} from "react-i18next";

export default function Install() {
    const {t} = useTranslation();
    const [steps, setSteps] = useState(1);
    const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod|mac/.test(userAgent);
    }
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const navigate = useNavigate();
    const installAndContinue = (e: React.MouseEvent) => {
        localStorage.setItem('first_launch', 'no');
        if (!isInStandaloneMode() && !isIos()) {
            promptToInstall();
        }

        navigate('/');
    }

    useEffect(() => {
        const first = localStorage.getItem('first_launch');
        if (first !== null) {
            navigate('/');
        }
    }, [])

    return (
        <>
            {steps === 0 && <UserSetup setSteps={setSteps}/>}
            {steps === 1 &&
                <div className='container p-4 col-12 col-md-4 ms-auto me-auto'>
                    <div className='text-center'>
                        <img
                            src={logo}
                            className="img-fluid mt-3 mb-3"
                            style={{
                                width: '35%'
                            }} alt=''/>
                    </div>
                    <div>
                        <h2>1. Description</h2>
                        <p>
                            {t('install.description')}
                        </p>
                    </div>
                    <div className='mt-3'>
                        <h2>{t('install.work')}</h2>
                        <p>
                            {t('work-text')}
                        </p>
                    </div>
                    <div className='mt-3'>
                        <h2>{t('install.geolocation')}</h2>
                        <p>
                            {t('install.geolocation-text')}
                        </p>
                    </div>
                    <div className='mt-3'>
                        <h2>4. Recommandation</h2>
                        <p>
                            {t('install.recommendation')}
                        </p>
                    </div>
                    <div className='text-center mt-3'>
                        <button className='btn btn-outline-dark'
                                onClick={event => setSteps(2)}>
                            {t('buttons.validate')}
                        </button>
                    </div>

                </div>}
            {steps === 2 && <AccountCreation setSteps={setSteps}/>}
            {steps === 3 && <CreateCorrespondents setSteps={setSteps} first={true}/>}
            {steps === 4 &&
                <div>
                    <div className='container p-4 col-12 col-md-4 ms-auto me-auto'>
                        <div className='text-center'>
                            <img
                                src={logo}
                                className="img-fluid mt-3 mb-3"
                                style={{
                                    width: '35%'
                                }} alt=''/>
                        </div>
                        <div className='text-center'>
                            <h3>Installer sur l'ecran d'accueil</h3>
                        </div>
                        {!isIos() &&
                            <div className='text-center mt-3'>
                                <button className='btn btn-outline-dark'
                                        onClick={e => installAndContinue(e)}>
                                    Valider et installer
                                </button>
                            </div>}

                        {isIos() &&
                            <div className='text-center mt-3'>
                                <p>
                                    1. Appuyez sur <i className="fa-solid fa-arrow-up-from-bracket"></i><br/>
                                    2. Puis rajouter sur l'ecran d'accueil
                                </p>
                                <button className='btn btn-outline-dark'
                                        onClick={e => installAndContinue(e)}
                                >Continuer
                                </button>
                            </div>}
                    </div>

                </div>}
        </>
    )
}