import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import one from "../assets/bg/1.jpg";
import two from "../assets/bg/2.jpg";
import three from "../assets/bg/3.jpg";
import four from "../assets/bg/4.jpg";
import logo from "../assets/picto.png";

const ChooseBackground = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [user] = useState(JSON.parse(localStorage.getItem('user') || '[]'))
    const [back, setBack] = useState(localStorage.getItem('background') || 'null')

    useEffect(() => {
        const first = localStorage.getItem('first_launch');
        if (first === null) {
            navigate('/install');
            return;
        }

    }, [])
    return (
        <div className='container col-12 col-md-4 ms-auto me-auto h-100'>
            <nav className="navbar bg-body-tertiary">
                <div className='navbar-nav'>
                    <Link to='/' className="navbar-brand mb-0 h1 d-none">{t('buttons.close')}</Link>
                </div>
                <form className="d-flex" role="search">
                    <div className='navbar-nav'>
                        <Link to='/' className="navbar-brand mb-0 h1">{t('buttons.close')}</Link>
                    </div>
                </form>
            </nav>
            <h3 className='mt-3 text-center'>Choix fond d'écran</h3>
            <div className='row text-center mb-3 mt-3'>
                <div className='col-md mb-5'>
                    <div>
                        <input type='radio' name='screen' value='null' onChange={() => {
                            setBack('null')
                            localStorage.removeItem('background');
                        }}
                               checked={back == 'null'}/>
                        Aucun
                    </div>
                </div>
                <div className='col-md'>
                    <img
                        src={one}
                        className="img-fluid mt-5"
                        style={{
                            width: '75%'
                        }} alt=''/>
                    <div>
                        <input type='radio' name='screen' value={one} onChange={() => {
                            setBack(one)
                            localStorage.setItem('background', one);
                        }}
                               checked={back === one}/>
                    </div>
                </div>
                <div className='col-md'>
                    <img
                        src={two}
                        className="img-fluid mt-5"
                        style={{
                            width: '75%'
                        }} alt=''/>
                    <div>
                        <input type='radio' name='screen' value={two} onChange={() => {
                            setBack(two)
                            localStorage.setItem('background', two);
                        }}
                               checked={back === two}/>
                    </div>
                </div>
                <div className='col-md'>
                    <img
                        src={three}
                        className="img-fluid mt-5"
                        style={{
                            width: '75%'
                        }} alt=''/>
                    <div>
                        <input type='radio' name='screen' value={three} onChange={() => {
                            setBack(three)
                            localStorage.setItem('background', three);
                        }}
                               checked={back === three}/>
                    </div>
                </div>
                <div className='col-md'>
                    <img
                        src={four}
                        className="img-fluid mt-5"
                        style={{
                            width: '75%'
                        }} alt=''/>
                    <div>
                        <input type='radio' name='screen' value={four} onChange={() => {
                            setBack(four)
                            localStorage.setItem('background', four);
                        }}
                               checked={back === four}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseBackground;