import logo from "../assets/picto.png";
import React from "react";
import {toast} from "react-toastify";

export default function UserSetup({setSteps}: { setSteps: any }) {
    const [key, setKey] = React.useState<string>("");

    const handleClick = () => {
        if(key === ''){
            toast.error('Clé vide', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({uuid: key, setup: true})
        };

        fetch(`${process.env.REACT_APP_API_URL}/check-key`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.user !== null){
                    localStorage.setItem('user_key', key);
                    setSteps(1);
                }else{
                    toast.error('Clé invalide', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })
    }

    return (
        <div className='container p-4 col-12 col-md-4 ms-auto me-auto'>
            <div className='text-center'>
                <img
                    src={logo}
                    className="img-fluid mt-3 mb-3"
                    style={{
                        width: '35%'
                    }} alt=''/>
                <h2>Urgent Assistance</h2>
                <div className='text-center mt-3'>
                    <p>Veuillez insérez votre clé d'identification unique (elle est dans votre email de validation de
                        commande) :</p>
                    <input placeholder='Clé unique'
                           type='text'
                           className='form-control mb-3'
                           value={key}
                           onChange={(e) => setKey(e.target.value)}
                    />
                    <button className='btn btn-outline-dark'
                            onClick={handleClick}>
                        Valider
                    </button>
                </div>
            </div>
        </div>
    )
}