import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import {Accordion} from "react-bootstrap";

const Notice = () => {
    const {t} = useTranslation();
    return (
        <div className='container col-12 col-md-4 ms-auto me-auto h-100'>
            <nav className="navbar bg-body-tertiary">
                <div className='navbar-nav'>
                    <Link to='/' className="navbar-brand mb-0 h1 d-none">{t('buttons.close')}</Link>
                </div>
                <form className="d-flex" role="search">
                    <div className='navbar-nav'>
                        <Link to='/' className="navbar-brand mb-0 h1">{t('buttons.close')}</Link>
                    </div>
                </form>
            </nav>
            <h3 className='mt-3 mb-3 text-center'>Notice d'utilisation</h3>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Présentation</Accordion.Header>
                    <Accordion.Body>
                        <strong>Dans l’urgence, chaque seconde compte.</strong><br/>
                        Urgent Assistance permet d’envoyer un SMS avec géolocalisation GPS vers 1 à 3 contacts par :
                        <ul>
                            <li>double-clic discret sur la touche SOS
                                L’envoi du SMS est confirmé par un bip sonore.
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Contacts d’urgence</Accordion.Header>
                    <Accordion.Body>
                        Les contacts d’urgence sont choisis
                        librement parmi les personnes de confiance dans ton entourage proche : famille, amis, voisins
                        qui peuvent venir de te porter
                        secours ou appeler les services d’urgence
                        ou la police.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Double clic</Accordion.Header>
                    <Accordion.Body>
                        Téléphone en main, il suffit de cliquer 2 fois sur le cercle d’urgence pour envoyer le SMS de
                        demande de secours : L’envoi du SMS est confirmé par un bip sonore
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>SMS et géolocalisation GPS</Accordion.Header>
                    <Accordion.Body>
                        Le SMS de mande de secours est libellé comme suit :<br/>
                        SOS de NOM PRENOM<br/>
                        +## # ## ## ## ##<br/>
                        localisé hps://www.google.com/maps?<br/>
                        Clique sur :
                        <ul>
                            <li>
                                le n° de téléphone pour rappeler directement
                                ton correspondant
                            </li>
                            <li>
                                l’hyperlien pour afficher sa géolocalisation GPS
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Fonds d’écran</Accordion.Header>
                    <Accordion.Body>
                        Tu peux choisir ton fonds d’écran dans notre gamme comprenant plusieurs thèmes. Ces fonds
                        d’écran sont destinés à une utilisation discrète de l’application dans une situation à risque.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Important</Accordion.Header>
                    <Accordion.Body>
                        Urgent Assistance fonctionne :
                        <ul>
                            <li>
                                uniquement quand elle est affichée en premier plan càd visible sur ton écran pour
                                l’activation du double-clic.
                            </li>
                            <li>
                                partout où vous avez du réseau et accès à internet
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default Notice;